.pt-details-content {
    margin-bottom: 24px;;

}

.pt-details-content .pt-header-section .pt-header-title {
    /* height: 52px; */
    text-align: left;
    /* font: normal normal medium 24px/28px Effra; */
    letter-spacing: 0px;
    color: #230871;
    /* opacity: 1; */
    /* overflow-wrap: break-word; */
}

.pt-details-content .pt-header-section .pt-header-subtitle {
    /* height: 22px; */
    text-align: left;
    /* font: normal normal normal 18px/18px Effra; */
    letter-spacing: 0px;
    color: #747C91;
    opacity: 1;
   
}
.pt-details-content .pt-points-section {
    display: flex;

}
.pt-details-content .pt-title {
    /* height: 22px; */
    text-align: left;
    /* font: normal normal normal 18px/22px Effra; */
    letter-spacing: 0px;
    color: #747C91B8;
    opacity: 1;
    margin-right: 8px;
}
.pt-details-content .pt-price-value {
    /* height: 21px; */
    text-align: left;
    /* font-size:21px ; */
 /* font: normal normal normal 18px/22px Effra; */
    color: #313133;
    opacity: 1;
    margin-left: 0 !important;
}
/* .pt-details-content  .pt-price-section {
    display: flex;
    align-items: center;
    margin-top: 16px;
} */
.pt-details-content  .pt-price-section .pt-price-value {
    /* height: 21px; */
    text-align: left;
    /* font: normal normal medium 26px/36px Effra; */
    /* font-size:21px ; */
    color: #313133;
    opacity: 1;
    margin-bottom: 0px;
}
.pt-details-content .pt-points-section {
    display: grid;
    grid-template-columns: 50% 50%;
}
.pt-details-content .pt-points-section .pt-points-missing {
    display: flex;
}


.pt-details-content .pt-points-section .pt-points-missing-section .pt-points-missing .pt-value {  
/* height: 26px; */
text-align: left;
/* font-size:26px; */
letter-spacing: 0px;
color: #F76D70;
opacity: 1;
margin-bottom: 0px;
}
.pt-details-content .pt-points-section .pt-points-missing-section .pt-valid-date {
    display: flex;
    margin-top: 16px;
    align-items: baseline;
}

.pt-details-content .pt-points-section .pt-points-missing-section .pt-valid-date .pt-value {
    height: 22px;
text-align: left;
font-size: 18px;
letter-spacing: 0px;
color: #757D91;
opacity: 1;
}

.pt-details-content .pt-points-section .pt-points-equals  {
    display: flex;
    flex-direction: column;

    margin-left: 24px;
}

.pt-details-content .pt-line {
    margin-top: 24px;
}
.pt-details-content .pt-points-section .pt-points-equals .pt-title {
    /* height: 22px; */
text-align: left;
/* font: normal normal normal 18px/22px Effra; */
letter-spacing: 0px;
color: #230871;
font-weight: 500;
margin-bottom: 7px;
}
.pt-details-content .pt-points-section .pt-points-equals a.pt-credit-link {
    height: 16px;
    text-align: left;
    /* font: normal normal normal 14px/17px Effra; */
    letter-spacing: 0px;
    color: #438b7f  !important;
}


