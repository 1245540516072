.react-datepicker__input-container input{
    background-color: white !important; 
  }

.react-datepicker__day--selected , .react-datepicker__day:hover{
    background-color: #00c2ab !important;
  }
  
  .react-datepicker__day--outside-month{
    visibility: hidden;
  }

  .react-datepicker__navigation-icon{
    top: 10px;
  }

  .react-datepicker__day{
    font-weight: bolder !important;
  }

  .react-datepicker__day--keyboard-selected{
    background-color: white !important;
    color: black !important;
  }

  .react-datepicker__day--disabled{
    color: gray !important;
    font-weight: 100 !important;
  }
  .date-border{
    border: 3px solid var(--primaryColor , #136982) !important;
  }