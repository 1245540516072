.Product-Details-Images {

}
.Product-Details-Images .Product-Image {
    width: 70px;
    height: 66px;
    border: 1px solid #EEE9EA;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
   

}
.Product-Details-Images .Product-Image:hover { 
    cursor: pointer;
    border: 2px solid #00AF9A;
}
.Product-Details-Images .Product-Image:not(:first-child) {
 margin-top: 16px;
}

.Product-Details-Images .Product-Image.selected {
border: 2px solid #00AF9A;
}