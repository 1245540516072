html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }
  
  .container {
    max-width: 960px;
  }
  
  .pricing-header {
    max-width: 700px;
  }
  
  /* .card-deck .card {
    min-width: 220px;
  } */
  
  .border-top { border-top: 1px solid #e5e5e5; }
  .border-bottom { border-bottom: 1px solid #e5e5e5; }
  
  .box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

  /* .card { */
    /* width: 16em; */
    /* margin-left: 55%; */
    /* position: absolute;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem; */
/* } */

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0 !important;
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #00c2ab !important;
  background-color: #00c2ab !important;
}

select {
  background-color: white;
  border: thin solid grey;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 8.25em 0.5em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.ar-select {
  background-color: white;
  border: thin solid grey;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 1em 0.5em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.btn-block {
  display: block;
  width: 100%;
}

/* .btn-primary {
  color: #fff;
  background-color: #00c2ab !important;
  border-color: #136982 !important;
} */

/* .btn-outline-primary{
  border-color: #00c2ab !important;
  color: #00c2ab !important;;
  } */
  
  /* .cardValue{
    min-width: 100%;
  } */
  
  #buttons li {
    background-color: transparent;
      border: 1px solid lightgray;
      float: left;
      list-style: none;
      text-align: center;
      margin-right: 20px;
      width: 95px;
      line-height: 35px;
  }
  
  #buttons li a {
    text-decoration: none;
    color: #FFFFFF;
    display: block;
  }
  
  /* .btn {
    display: inline-block;
    margin-right: 10px !important;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem 0.75rem ;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  } */
  
  .btn-outline-primary {
    border-color: lightgray !important;
    color: #00c2ab !important;
  }
  
  .text{
    color:darkblue
  }
  
  .giftcolor{
    color:gray
  }
  
  /* .btn-outline-primary:hover{
    background-color: #fff !important;
      border-color: #00c2ab !important;
      color: #00c2ab !important;
      border: 2px solid;
  }   */