
.hassad-Product-details-page {
    display: flex;
    margin-top: 38px;
    margin-left: 48px;
    
   
   
}
.hassad-Product-details-page .hassad-Product-details-images {
    margin-right: 16px;
    width: 70px;
   


}

.hassad-Product-details-page .hassad-Product-details-images {
    margin-right: 16px;
    width: 70px;
   


}

.hassad-Product-details-page .hassad-Product-details-image-selected {
    margin-right: 16px;
    
}
.hassad-Product-details-page .hassad-Product-details-content{
    margin-right: 16px;
    width: 440px; 
    
}
.hassad-Product-details-page .hassad-Product-details-shipping {
    /* border: 1px solid gray;
    height: 100px;
    width: 216px; */
    
    
}

.hassad-Product-details-content .product-highlights .pt-header {
  
height: 18px;
text-align: left;
/* font: normal normal medium 18px/25px Effra; */
letter-spacing: 0px;
color: #230871;
opacity: 1;
}
.hassad-Product-details-content .product-highlights ul.pt-ht-list {

    color: #747C91;
    margin-top: 24px;
}


ul.pt-ht-list  li{ 
    text-align: left;
    /* font: normal normal normal 14px/18px Effra; */
    letter-spacing: 0px;
    color: #747C91;
    opacity: 1;
    margin-left: -1em;
  }

  .hassad-Product-details-page .hassad-Product-details-shipping {
    /* border: 1px solid gray;
    height: 100px;
    width: 216px; */
    width: 216px;
    margin-left: 16px;
    margin-right: 16px;
    
}


.mobile-view-gifts {
    padding: 14px;
     
}
.mobile-view-gifts  .pt-line {
    margin-top: 24px;
}

.mobile-view-gifts .card-details {
    border: none;
    margin: 0px;
}

.mobile-view-gifts .card-details .card-body {
      padding: 0px;
}
.mobile-view-gifts .mobile-view-gift-details {
    margin-top: 60px;
    margin-left: 24px;
    margin-bottom: 24px;
}
.mobile-view-gifts .mobile-view-gift-details  .pt-title{
 
    text-align: left;
/* font: normal normal normal 18px/22px Effra; */
letter-spacing: 0px;
color: #230871;
font-weight: 500;
margin-right: 5px;

}
.mobile-view-gifts .mobile-view-gift-details  .pt-title-other{
 
    text-align: left;
/* font: normal normal normal 18px/22px Effra; */
letter-spacing: 0px;
color: #230871;
font-weight: 500;
}

.mobile-view-gifts .mobile-view-gift-valid-date .pt-title {

}

/* @media only screen and (min-width: 1400px) {
.hassad-Product-details-page{
    justify-content: space-around;
}
.hassad-Product-details-page .hassad-Product-details-content {
    margin-right: 16px;
    width: 1158px;
}
} */