.Product-Image-selected {
    display: flex;
    height: 430px;
    /* width: 400px; */
    justify-content: center;
    align-items: center;
    border: 1px solid #EEE9EA;
    opacity: 1;
    padding: 28px 96px;

}
/* .Product-Image-selected .Product-Image-display {
    justify-content: center;


} */