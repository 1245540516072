:root{
    --primaryColor:#4681C2;
    --primaryTextColor:#0e5979;
    --secondaryTextColor:#136982;
    --ternaryTextColor:#03a8b6;
    --primaryBtnBgColor:#00B2AE;
    --secondaryBtnBgColor:#FFF;
    --ternaryBtnBgColor:#eeeeee;
    --primaryBtntextColor:#FFF;
    --primaryLinkColor:#03a8b6;
    --primaryBroderColor:#00B2AE;
    --linkColor:#4d4f5c;
    --linkActive: #03a8b6;
    --linkHover: #03a8b6;
    --primaryBtnBackgroundColor:#00B2AE;
    --primaryBtnBorderColor:#00B2AE;
    --primaryBtnFontColor:#;
    --secondaryBtnBackgroundColor:#fff;
    --secondaryBtnBorderColor:#136982;
    --secondaryBtnFontColor:#136982;
    --headings:#0e5979;
    --sunHeadings:#;
    --productTitleColor:#136982;
    --productBrandColor:#4d4f5c;
    --productValueColor:#4d4f5c;
    --secondaryColor:#03a8b6;
    --textcolor:#4d4f5c;
    --primaryBgColor:#136982;
    --secondaryBgcColor:#03a8b6;
}

.header_footer{
  background-color: var(--primaryColor,#4681C2) !important;
}
.footer{
  background-color: var(--primaryColor,#4681C2) !important;
  margin-top: auto;
}
.primary-search-btn{
background-color: var(--primaryBtnBgColor,#00B2AE) !important;
}
.category-link{
    color: var(--linkColor, #4d4f5c);
}
.category-link:hover{
    color: var(--linkHover, #6fac45) !important ;
}
.active-cat{
    color: var(--linkActive, #03a8b6) !important ;
    /* border-color: #00c2ab !important; */
  }
  .active-link{
    color: var(--linkActive, #03a8b6) !important ;
    /* border-color: #00c2ab !important; */
  }
  .btnTxt{
    color:var(--secondaryTextColor,#136982) !important;
  }
  .btnTextDark{
    background-color: #063041 !important;
  }
  .color-green{
    color: #03a8b6 !important;
  }
  .color-skyblue{
    color: var(--linkActive,#03a8b6)  !important;
  }
  .headings{
      color: var(--primaryTextColor,#0e5979 ) !important;
  }
  .sub-headings{
    color: var(--textcolor,#4d4f5c ) !important;
  }
  .product_title{
      color: var(--secondaryTextColor,#136982);
  }
  .product_brand{
      color: var(--productBrandColor,#4d4f5c);
  }
  .product_value{
      color: var(--productValueColor,#4d4f5c)
  }
  .primary-color{
    color: var(--secondaryTextColor,#136982) !important;
  }
  .second-color{
    color: var(--ternaryTextColor , #03a8b6) !important;
  }
  .selected-value{
    border: 2px solid var(--primaryBroderColor,#136982) !important ;
  }
  .primary-btn{
    color:var(--primaryBtntextColor,#FFF) !important;
    background-color: var(--primaryBtnBgColor, #00B2AE) !important ;
  }
  .btn-sky{
    color: var(--primaryBtntextColor,#FFF) !important;;
    background-color: var(--primaryBtnBgColor, #00B2AE) !important;
  }
  .secondary-color{
    color: var(--primaryBtnBgColor, #00B2AE) !important;
  }
  .muted-color{
    color: #484A56;
  }
  .bg-color{
    background-color: var(--secondaryTextColor ,#136982) !important;
  }
  .border-color{
    border: 2px solid var(--primaryBtnBgColor) !important;
  }
  .loginModal{
    text-decoration: underline;
    color: var(--linkActive , #6fac45) !important
}

.btn-secondary{
  color: var(--primaryColor) !important;
 border-color: 3px solid var(--secondaryTextColor) !important;
 background-color: #FFF !important;
}
.form-control:focus{
 border-color: var(--primaryColor,#136982 ) !important;
}

.height-25{
  height : 2.5rem
}
#lg-fix{
width: 10rem;
}