/* Border */
.border-none{
    border: none;
}
.border-1{
    border: 1px solid #dee2e6
}
.border-2{
    border: 3px solid #dee2e6
}
.border-3{
    border: 6px solid #dee2e6
}
.border-4{
    border: 10px solid #dee2e6
}
.border-5{
    border: 15px solid #dee2e6
}
.border-radius-1{
    border-radius: 10px;
}
/* Font Styles */
.fs-1{
    font-size: 2.5rem ;
}
.fs-2{
font-size: 2rem ;
}
.fs-3{
font-size: 1.75rem;
}
.fs-4{
font-size: 1.5rem  ;
}
.fs-5{
font-size: 1.25rem ;
}
.fs-6{
font-size: 1rem ;
}
.fs-7{
font-size: 0.75rem ;
}
.fw-bold{
font-weight: 600;
}
.fw-bolder{
font-weight: 700;
}
.fw-normal{
font-weight: 400;
}

/* Grid Layout */
.gap-1{
gap:0.25rem
}
.gap-2{
gap:0.5rem
}
.gap-3{
gap:1rem
}
.gap-4{
gap:1.5rem
}
.gap-5{
gap:3rem
}

.gridRates{
    margin-right: 1rem !important;
    margin-bottom: 1rem !important;
}

.d-grid{
display: grid;
}
.grid-cols-1{
grid-template-columns: repeat(1,minmax(0, 1fr));
}
.grid-cols-2{
grid-template-columns: repeat(2,minmax(0, 1fr));
}
.grid-cols-3{
grid-template-columns: repeat(3,minmax(0, 1fr));
}
.grid-cols-4{
grid-template-columns: repeat(4,minmax(0, 1fr));
}
.grid-cols-5{
grid-template-columns: repeat(5,minmax(0, 1fr));
}
.grid-cols-6{
grid-template-columns: repeat(6,minmax(0, 1fr));
}
.grid-cols-7{
grid-template-columns: repeat(7,minmax(0, 1fr));
}
.grid-cols-8{
grid-template-columns: repeat(8,minmax(0, 1fr));
}
.grid-cols-9{
grid-template-columns: repeat(9,minmax(0, 1fr));
}
.grid-cols-10{
grid-template-columns: repeat(10,minmax(0, 1fr));
}
.grid-cols-11{
grid-template-columns: repeat(11,minmax(0, 1fr));
}
.grid-cols-12{
grid-template-columns: repeat(12,minmax(0, 1fr));
}
.grid-rows-1{
grid-template-rows: repeat(1,1fr);
}
.grid-rows-2{
grid-template-rows: repeat(2,1fr);
}
.grid-rows-3{
grid-template-rows: repeat(3,1fr);
}
.grid-rows-4{
grid-template-rows: repeat(4,1fr);
}
.grid-rows-5{
grid-template-rows: repeat(5,1fr);
}
.grid-rows-6{
grid-template-rows: repeat(6,1fr);
}
.grid-rows-7{
grid-template-rows: repeat(7,1fr);
}
.grid-rows-8{
grid-template-rows: repeat(8,1fr);
}
.grid-rows-9{
grid-template-rows: repeat(9,1fr);
}
.grid-rows-10{
grid-template-rows: repeat(10,1fr);
}
.grid-rows-11{
grid-template-rows: repeat(11,1fr);
}
.grid-rows-12{
grid-template-rows: repeat(12,1fr);
}


@media(min-width:767px){
    .border-md-none{
        border: none;
    }
    .border-md-1{
        border: 1px solid #dee2e6
    }
    .border-md-2{
        border: 3px solid #dee2e6
    }
    .border-md-3{
        border: 6px solid #dee2e6
    }
    .border-md-4{
        border: 10px solid #dee2e6
    }
    .border-md-5{
        border: 15px solid #dee2e6
    }
    .fs-md-1{
        font-size: 2.5rem ;
    }
    .fs-md-2{
        font-size: 2.0rem ;
    }
    .fs-md-3{
        font-size: 1.75em;
    }
    .fs-md-4{
        font-size: 1.5rem;
    }
    .fs-md-5{
        font-size: 1.25em ;
    }
    .fs-md-6{
        font-size: 1rem ;
    }
    .fs-md-7{
        font-size: 0.75rem ;
    }
    .fw-md-bold{
        font-weight: 600;
    }
    .fw-md-bolder{
        font-weight: 700;
    }
    .fw-md-normal{
        font-weight: 400;
    }
    .gap-md-1{
        gap:0.25rem
    }
    .gap-md-2{
        gap:0.5rem
    }
    .gap-md-3{
        gap:1rem
    }
    .gap-md-4{
        gap:1.5rem
    }
    .gap-md-5{
        gap:3rem
    }
    .d-md-grid{
        display: grid;
    }
    .grid-cols-md-1{
        grid-template-columns: repeat(1,minmax(0, 1fr));
    }
    .grid-cols-md-2{
        grid-template-columns: repeat(2,minmax(0, 1fr));
    }
    .grid-cols-md-3{
        grid-template-columns: repeat(3,minmax(0, 1fr));
    }
    .grid-cols-md-4{
        grid-template-columns: repeat(4,minmax(0, 1fr));
    }
    .grid-cols-md-5{
        grid-template-columns: repeat(5,minmax(0, 1fr));
    }
    .grid-cols-md-6{
        grid-template-columns: repeat(6,minmax(0, 1fr));
    }
    .grid-cols-md-7{
        grid-template-columns: repeat(7,minmax(0, 1fr));
    }
    .grid-cols-md-8{
        grid-template-columns: repeat(8,minmax(0, 1fr));
    }
    .grid-cols-md-9{
        grid-template-columns: repeat(9,minmax(0, 1fr));
    }
    .grid-cols-md-10{
        grid-template-columns: repeat(10,minmax(0, 1fr));
    }
    .grid-cols-md-11{
        grid-template-columns: repeat(11,minmax(0, 1fr));
    }
    .grid-cols-md-12{
        grid-template-columns: repeat(12,minmax(0, 1fr));
    }
    .grid-rows-md-1{
        grid-template-rows: repeat(1,1fr);
    }
    .grid-rows-md-2{
        grid-template-rows: repeat(2,1fr);
    }
    .grid-rows-md-3{
        grid-template-rows: repeat(3,1fr);
    }
    .grid-rows-md-4{
        grid-template-rows: repeat(4,1fr);
    }
    .grid-rows-md-5{
        grid-template-rows: repeat(5,1fr);
    }
    .grid-rows-md-6{
        grid-template-rows: repeat(6,1fr);
    }
    .grid-rows-md-7{
        grid-template-rows: repeat(7,1fr);
    }
    .grid-rows-md-8{
        grid-template-rows: repeat(8,1fr);
    }
    .grid-rows-md-9{
        grid-template-rows: repeat(9,1fr);
    }
    .grid-rows-md-10{
        grid-template-rows: repeat(10,1fr);
    }
    .grid-rows-md-11{
        grid-template-rows: repeat(11,1fr);
    }
    .grid-rows-md-12{
        grid-template-rows: repeat(12,1fr);
    }


}