.cat-title-section {
    display: flex;
    align-items: flex-start;
}
.cat-title-section .title-section {
    display: flex;
    flex-direction: column;
    flex: 1;
   
}
.cat-title-section .show-all-link {
    height: 18px;
    font: normal normal medium 18px/22px Effra;
    letter-spacing: 0px;
    color: #00B4B1;
    opacity: 1;
}
.cat-section {
    color: red;
    display: flex;
    flex-wrap: wrap;
    
}
.cat-section .cat-item {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}
.cat-section .cat-item:not(:last-child) { 
    margin-right: 16px;
    
}
/* cat-choose  */
.cat-section.cat-choose .cat-item .cat-img {
    width: 324px;
    height: 172px;
}
.cat-section.cat-choose .cat-item .cat-label {
    height: 18px;
    font: 18px;
    letter-spacing: 0px;
    color: #230871;
    opacity: 1;
}

/* cat-featured  */
.cat-section.cat-featured .cat-item .cat-img {
    width: 260px;
    height: 172px;
}
.cat-section.cat-featured .cat-item .cat-label {
    height: 16px;
    font: 16px;
    letter-spacing: 0px;
    color: #230871;
    text-align: center;
    opacity: 1;
}
/* cat-shopbrand  */
.cat-section.cat-shopbrand .cat-item {
    justify-content: center;
    align-items: center;
    width: 154px;
    height: 72px;
    background: #EEEEEE 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 16px;
}
.cat-section.cat-shopbrand .cat-item .cat-img {
    width: 100px;
    height:60px;
}
/* Shop by brand margins */
.shop-cat:nth-child(even){
    margin-right: 6px;
}
.shop-cat:nth-child(odd){
    margin-left: 6px;
}
@media(max-width:375px){ 
    .cat-section {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
    /* cat-choose  */
.cat-section.cat-choose .cat-item .cat-img {
    width: 146px;
    height: 86px;
}
.cat-section .cat-item:not(:last-child) { 
    margin-right: 8px;
    
}

}

@media(max-width:800px){
    .cat-section img{
        width: 100% !important;
        height: 100% !important;
    }
}

.categoryText {
    width:100%;    
    height:100%;
    position: absolute;
    top: 0;
    left: 0px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    color: white;
  }

/* horizontal scroll button animation  */
  .horizontal-scroll {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    padding-left: 50px;
    padding-right: 50px;
    scroll-behavior: smooth;
  }
  
  .list {
    /* display: flex; */
    flex-wrap: nowrap;
    padding: 0;
    margin: 0;
    list-style-type: none;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  
  .scroll-button {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
  }
  
  .left {
    left: 20px;
  }
  
  .right {
    right: 20px;
  }
  .hidden{
    display: none;
  }