.pt-variants-section {
    margin-top: 24px;
    margin-bottom: 24px;
}
 .pt-variants-section .pt-variants{
    display: flex;

}

.pt-variants{
    overflow-x: scroll ! important;
  -webkit-overflow-scrolling: touch ! important;
 overflow-y: hidden ! important;
}

 .pt-variants-section .pt-variant {
    display: flex;
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E6E7;
    opacity: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   

}
.pt-variants-section .pt-variant:hover, .pt-variants-section .pt-variant.selected {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 2px solid #00AF9A;
    opacity: 1;
}
.pt-variants-section  .pt-variant.disable-or-hide {
    display: none;
    /* pointer-events: none;
    background-color: rgb(247 245 240) !important;
    color: lightgray !important; */

    /* color: #c0c0c0;
        background-color: #ffffff; */
}
.pt-variants-section .pt-variants-title {
    margin-bottom: 8px;
}
.pt-variants-section .pt-variant .pt-variant-title{
  
    /* text-align: center; */
    /* font: normal normal normal 16px/18px Effra; */
    letter-spacing: 0px;
    color: #747C91;
    opacity: 1;
    /* padding: 8px 19px 8px 19px; */
    padding-left:8px;
    padding-right: 8px;
    }
.pt-variants-section .pt-variant-color {
 width: 18px;
 height: 18px;
 margin-left: 8px;
}
.pt-variants-section .pt-variant-color +  .pt-variants-section .pt-variants-title  {
    padding-left: 12px;
    padding-right: 12px;
}
.pt-variants-section .pt-variants-title .pt-title {
    height: 20px;
    /* font: normal normal normal 16px/22px Effra; */
    letter-spacing: 0px;
    color: #747C91;
}
.pt-variants-section .pt-variants-title .pt-value {
height: 20px;
text-align: left;
/* font: normal normal normal 16px/22px Effra; */
letter-spacing: 0px;
color: #230871;
margin-left: 12px;
}
.pt-variants-section .pt-variant:not(:last-child) {
   margin-right: 16px;
   

}
