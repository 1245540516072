.buyself{
    color: darkblue !important;
    border: 1px solid darkblue;
}

/* giftcardedit */
.giftcardedit{
  border: 2px dashed lightgray !important;
  position: relative;
}
.giftcardeditbutton{
  background-color:white;
  border-color: darkblue;
  position: absolute;
  left: 80%;
  top: -3%;
}

/* giftcardedit desktop */
.giftcardeditdesk{
  border: 2px dashed lightgray;
}
.giftcardeditmssg{
  line-height:1.7;
  opacity: 0.8;
}
.giftcardeditbuttondesk{
  background-color:white;
  border-color: darkblue;
  position: absolute;
  left: 90%;
  top: -3%;
  font-weight: 600;
  z-index: 10;
}
.giftcardeditdesk{
  position: relative;
}

/* breadcrumb */
.check,.check2{
    display: flex;
    align-items:center;
  }
  .check1{
    display: flex;
    align-items:center;
    width: 8rem;
    justify-content: center;
  }
  .last-check{
    width: 10rem !important;
  }
  .steps{
    border: 1px solid  rgba(29, 20, 20, 0.1);
    width: 38%;
  }
  .m-steps{
    border: 1px solid  rgba(29, 20, 20, 0.1);
    width: 13%;
  }
  
  /* .check::after{
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    background: rgba(29, 20, 20, 0.1);
    width: 175%;
    left: 110%;
    top: 55%;
  }

  .check2::after{
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    background: rgba(29, 20, 20, 0.1);
    width: 175%;
    right: 120%;
    top: 55%;
  }
   */
  .checkwidth::after{
    width: 400%;
  }
  .maincheck{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .checkname{
    margin-left: 10px;
  }
  .checkname2{
    margin-right: 10px;
  }
  .maininput{
    box-sizing: border-box;
    appearance: none;
    background: white;
    outline: 2px solid #00C2AB;
    width: 10px;
    height: 10px;
  }
  .bigcheck{
    width: 19px;
    height: 19px;
    background-color: #00C2AB;
  }
  .normalcheck{
    width: 10px;
    height: 10px;
    background-color: #00C2AB;
  }
  
  @media (max-width:468px){
    .maincheck{
      padding: 10px 10px 0 10px;
    }
    .checkname, .checkname2{
      font-size: 0.8rem !important;
    }
    .bigcheck{
      width:15px;
      height: 15px;
    }
    .check::after{
      width:0%;
    }
    .checkwidth::after{
      width:0%;
    }
  }
 
 

/* SelectOccassion */
.mainheadselect{
    font-weight: 600;
}

.mySwiper img{
    width: 105%;
    height: auto;
}

.pro{
  position: absolute;
  bottom: 150px;
}
.pro2{
  margin-top: 155px;
}
.pro button, .pro2{
  width: 90%;
}
.Selectmain {
  overflow: hidden;
  height: 300px;
}

@media (min-width:468px){
  .mainheadselect{
    font-size: 1.5rem;
  }
}


/* SelectOccassion slider pagination css */
.swiper-container {
  overflow: unset !important;
}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: -30px !important;
  left: 0 !important;
  width: 100% !important;
}
.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  background: url('../../../assests/img/giftflow/pagination.png') !important;
  background-size: contain !important;
}
.selectedborder{
  border: 4px solid #007aff !important;
}




/* yourmessage */
.yourinput{
    width: 100%;
    height: 70px;
}
.messageicon{
  width: 3.5%;
}
.desktopmessageicon{
  width: 1.7%;
}


/* form component */

.formmessagetype{
  border: 1px solid lightgray;
  padding: 5px 30px;
}
.messactive{
  border: 3px solid #00C2AB;
  padding: 5px 30px;
}
.fs-small{
  font-size: 12px;
}
.pro1{
  width: 92%;
}

/* Customdesign */
.customtab{
  border-radius: 20px;
}
.customtabactive{
  background-color:#00C2AB;
  color: white;
}
.upload-file .upload-box {
  margin-bottom: 10px;
}
.upload-file{
  width:50%;
}
.upload-file1{
  width:90%;
}
.upload-box {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #eee;
  position: relative;
  position: relative;
}
.upload-box:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  border: 4px dashed #ddd;
  margin: 10px;
}
.upload-box i {
  color: #ddd;
  font-size: 60px;
  margin-bottom: 20px;
}
.uploadInput{
  z-index: 10;
  opacity: 0;
  height:100%;
  padding: auto 0;
}
.upload-name{
  position: absolute;
  top:50%;
}
.hoverCustom:hover{
  color: white !important;
}

/* EMail preview css */
.emailimgtop{
  height:3.5rem;
  background-color:#00C2AB;
  color: white;
}
.emailimgtop1{
  height:3.5rem;
  background-color: darkgray;
  color: white;
}
.EmailPreviewMain .modal-backdrop{
  align-items: unset !important;
}
.emailImage{
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

/* hovering pointer */
.hoverpointer{
    cursor:pointer;
}
.messageproceed{
  Width: 90%;
  position: absolute;
  bottom: 20%;
  left: 5%;
}